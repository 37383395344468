<template>
    <BaseModal :is-visible="modalStore.activeModals['follow-dossiers'] === true">
        <template #title>Beheer dossiers</template>
        <template #default>
            <DossierSelector
                :selected-dossier-ids="followingDossierIds"
                @select-theme="followTheme"
            >
                <template #dossier-button="{ dossier }">
                    <DossierFollowButton
                        :dossier-id="dossier.id"
                        size="medium"
                    />
                </template>

                <template #select-theme-button="{ dossierIds }">
                    <BaseButton
                        class="base-button--tertiary"
                        size="small"
                        icon="alert"
                        :disabled="isSaving"
                        @click="followTheme(dossierIds)"
                    >
                        Volg alle dossiers
                    </BaseButton>
                </template>
            </DossierSelector>
        </template>
    </BaseModal>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
import { useModalStore } from '~/store/modal';

const modalStore = useModalStore();

const user = computed(() => {
    return useAuthStore().userModel;
});

const isSaving = ref(false);

const followingDossierIds = computed(() => {
    return toValue(toValue(user).followingDossierIds);
});

const followTheme = async(dossierIds) => {
    isSaving.value = true;
    await toValue(user).followDossiers(dossierIds);
    isSaving.value = false;
};
</script>
